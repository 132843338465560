import vue from 'vue'
import axios from 'axios'
import responseStatus from './status'
const baseApi = '/n2/api/'

// 创建个vue实例
const _this = new vue()

class HTTP {
  constructor() {
    this.REQUEST_GET = 'get'
    this.REQUEST_POST = 'POST'
    this.HTTPSTATUS = responseStatus
  }

  /**
   * @description 请求，参数与axios一致
   * @see https://github.com/axios/axios#request-config
   */
  request(config) {
    let header = config.header
      ? config.header
      : {
          'Content-Type': 'application/json;charset=UTF-8',
        }
    // axios实例，拦截请求&响应
    const service = axios.create({
      baseURL: baseApi,
      timeout: 30000,
      headers: {
        token:
          localStorage.getItem('token') &&
          localStorage.getItem('token') != 'undefined'
            ? localStorage.getItem('token')
            : '',
        source: process.env.VUE_APP_TYPE,
        ...header,
      },
    })

    service.interceptors.response.use((response) => {
      const res = response.data
      if (res.status === responseStatus.SUCCESS) {
        return res
      } else {
        if (typeof res == 'string' && res.indexOf('html') > -1) {
          return res
        } else {
          if (res.status == responseStatus.USER_NON_LOGIN) {
            localStorage.setItem('token', '')
          }
          throw new Error(res.msg)
        }
      }
    })
    return new Promise((resolve, reject) => {
      if (config.type) {
        service(config)
          .then((res) => {
            resolve(res)
          })
          .catch((error) => {
            _this.$toast(error.message, 'fail')
            reject(null)
          })
      } else {
        // _this.$loading(true);
        service(config)
          .then((res) => {
            resolve(res)
            // _this.$loading(false);
          })
          .catch((error) => {
            // _this.$loading(false);
            _this.$toast(error.message, 'fail')
            reject(null)
          })
      }
    })
  }

  /**
   * @description 快捷get请求
   * @see https://github.com/axios/axios#axios-api
   */
  get(config) {
    return this.request({
      method: this.REQUEST_GET,
      ...config,
    })
  }

  /**
   * @description 快捷post请求
   * @param {url} Y 请求地址
   * @param {data} N 请求数据
   * @see https://github.com/axios/axios#axios-api
   */
  post(config) {
    return this.request({
      method: this.REQUEST_POST,
      ...config,
    })
  }
}

export default HTTP
