/*eslint-disable*/

import LoadingComponent from "./index.vue";


export default {
  install(_Vue, options) {
    const LoadingConstructor = _Vue.extend(LoadingComponent);

    const instance = new LoadingConstructor(); 
    instance.$mount(document.createElement("div"));

    document.body.appendChild(instance.$el);

    _Vue.prototype.$loading = (flag) => {
      instance.show = flag;
    };
  },
};
