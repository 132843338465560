import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "amfe-flexible";
import "@/styles/reset.styl";
import store from "./store";

// 全局使用toast
import showToast from "@/components/toast/index";
Vue.use(showToast);

//全局loading组件
import loading from "@/components/loading/index";
Vue.use(loading);

//挂在全局方法
import common from "@/utils/common/index";
Vue.prototype.$common = common;

Vue.config.productionTip = false;

new Vue({
    store,
    router,
    render: (h) => h(App),
}).$mount("#app");
