const common = {
  // 保留两位小数
  reduceSecond: (val) => {
    if (isNaN(val)) {
      val = "0";
    }
    var sign = val == (val = Math.abs(val));
    val = Math.floor(val * 100 + 0.50000000001);
    var cents = val % 100;
    val = Math.floor(val / 100).toString();
    if (cents < 10) {
      cents = "0" + cents;
    }
    for (var i = 0; i < Math.floor((val.length - (1 + i)) / 3); i++) {
      val =
        val.substring(0, val.length - (4 * i + 3)) +
        "," +
        val.substring(val.length - (4 * i + 3));
    }

    return (sign ? "" : "-") + val + "." + cents;
  },
  // 周几转换
  convertData: (date) => {
    return date.replace("星期", "周");
  },
  //获取地址栏参数
  getQueryString: (name) => {
    let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");

    let r = window.location.search.substr(1).match(reg);

    if (r != null) return unescape(r[2]);

    return null;
  },
  //判断是否是在微信环境里面
  isWeChat: () => {
    var ua = navigator.userAgent.toLowerCase();
    var isWeixin = ua.indexOf("micromessenger") != -1;
    if (isWeixin) {
      return true;
    } else {
      return false;
    }
  },
  //把url转换成json对象
  getQueryUrlToJson: (str) => {
    let arr = decodeURIComponent(str.split("?")[1]).split("&"); //先通过？分解得到？后面的所需字符串，再将其通过&分解开存放在数组里
    let obj = {};
    for (let i of arr) {
      obj[i.split("=")[0]] = i.split("=")[1]; //对数组每项用=分解开，=前为对象属性名，=后为属性值
    }
    return obj;
  },
  //获取缓存
  getStorage(key) {
    return key == "information" || key == "calcKey"
      ? JSON.parse(localStorage.getItem(key))
      : localStorage.getItem(key);
  },
  //设置缓存
  setStorage(key, value) {
    localStorage.setItem(
      key,
      typeof value == "object" ? JSON.stringify(value) : value
    );
  },

  //时间戳转换年月日
  formatDate(date) {
    let newDate = new Date(date);
    var YY = newDate.getFullYear() + "-";
    var MM =
      (newDate.getMonth() + 1 < 10
        ? "0" + (newDate.getMonth() + 1)
        : newDate.getMonth() + 1) + "-";
    var DD =
      newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
    return YY + MM + DD;
  },
};

export default common;
