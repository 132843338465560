export default [
  {
    path: '/awaitpay',
    name: 'awaitPay',
    meta: {
      title: '待支付',
    },
    component: () => import('@/views/await-pay/index.vue'),
  },
]
