import Vue from "vue";
import VueRouter from "vue-router";
import PayRoutes from "./pay";
import AgreementRoutes from "./agreement";
import loginRoutes from "./login";
import store from "../store";
import OrderModel from "@/models/order";
const oc = new OrderModel();
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        meta: {
            title: "首页",
        },
        component: () => import("@/views/home/home.vue"),
    },
    ...loginRoutes,
    ...AgreementRoutes,
    ...PayRoutes,
];

const router = new VueRouter({
    mode: "history",
    base: "v2",
    routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.title) {
    //判断是否有标题
    document.title = to.meta.title
  }
  let userOrder = await oc
    .detail({ orderId: to.query.orderId || from.query.orderId })
    .then((res) => {
      return {
        id: res.data.userId,
        name: res.data.userName,
        phone: res.data.userPhone,
      }
    })
    .catch((error) => {
      console.error(error.msg || error.message)
      return {}
    })
  await store.dispatch('user/AUTH')

    if (!store.state.user.userId) {
        store.commit("user/USER", userOrder);
    } else if (store.state.user.userId !== userOrder.id) {
        // 对比本地信息,非当前登录用户,清除当前登录用户信息
        store.commit("user/CLEAR_USER");
        store.commit("user/USER", userOrder);
    }
    store.dispatch("user/AUTH");
    next();
});

export default router;
