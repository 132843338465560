<template>
  <div class="cust_toast" :class="type" v-if="showToast">{{ message }}</div>
</template>

<script>
export default {
  /**
   * 自己封装的Toast v0.2
   * params: showToast Boolean 是否激活toast 默认 false
   * params: type String       toast提示类型 共normal success，fail，warning 四个选项 默认normal
   * params: message String    toast消息
   * params: duration Number      toast显示时间 默认 1000ms
   * */
  name: 'CustToast',
  data() {
    return {
      showToast: true,
      type: 'normal',
      message: '消息提示',
      duration: 1000,
    }
  },
  computed: {
    iconSrc() {
      let tipType = ['normal', 'success', 'warning', 'fail']
      if (tipType.includes(this.type)) {
        return ''
      } else {
        throw 'Toast type数据只允许为 normal, success, warning, fail 四种其中的一种，默认为normal'
      }
    },
  },
}
</script>

<style scoped>
.cust_toast {
  padding: 20px 20px;
  text-align: center;
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 35%;
  background: rgb(233, 233, 235);
  border-radius: 5px;
  transform: translate(-50%);
  animation: show-toast 0.2s;
  color: #909399;
  font-size: 26px;
}

@keyframes show-toast {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.success {
  color: #67c23a;
  background: rgb(225, 243, 216);
}

.warning {
  color: #e6a23c;
  background: rgb(250, 236, 216);
}

.fail {
  color: #f56c6c;
  background: rgb(253, 226, 226);
}
</style>
