const LOCAL = {
  USER: 'USER',
  WX: 'WX',
  TOKEN: 'token',
}

export default {
  namespaced: true,
  state: () => {
    return {
      userId: null,
      userName: null,
      userPhone: null,
      token: null,
      openId: null,
      unionId: null,
    }
  },
  getters: {
    isLogin(state) {
      return !!state.token
    },
    userInfo(state) {
      return {
        id: state.userId,
        name: state.userName,
        phone: state.userPhone,
      }
    },
    wx(state) {
      return {
        openId: state.openId,
        unionId: state.unionId,
      }
    },
  },
  mutations: {
    AUTH(state, token) {
      state.token = token
      localStorage.setItem(LOCAL.TOKEN, token)
    },
    USER(state, u) {
      state.userId = u.id
      state.userName = u.name
      state.userPhone = u.phone
      localStorage.setItem(LOCAL.USER, JSON.stringify(u))
    },
    WX(state, data) {
      state.openId = data.openId
      state.unionId = data.unionId
      localStorage.setItem(LOCAL.WX, JSON.stringify(data))
    },
    CLEAR_USER(state) {
      localStorage.clear()
      state.token = null
    },
  },
  actions: {
    AUTH({ commit }) {
      const token = localStorage.getItem(LOCAL.TOKEN)
      if (token) {
        commit('AUTH', token)
      }

      try {
        const user = localStorage.getItem(LOCAL.USER)
        commit(LOCAL.USER, JSON.parse(user))
        const wx = localStorage.getItem(LOCAL.WX)
        commit(LOCAL.WX, JSON.parse(wx))
      } catch (error) {
        console.log(error)
      }
    },
    USER({ commit }) {
      const user = localStorage.getItem(LOCAL.USER)
      if (user) {
        commit(LOCAL.USER, JSON.parse(user))
      }
    },
    WX({ commit }) {
      try {
        const wx = localStorage.getItem(LOCAL.WX)
        commit(LOCAL.WX, JSON.parse(wx))
      } catch (error) {
        console.log(error)
      }
    },
  },
}
