/**
 * http响应状态码
 *
 SUCCESS(0, "成功！"),
 DEFAULT_FAIL(-1024, "服务器异常！"),
 USER_NON_EXISTENT(-101101001, "用户不存在！"),
 PASSWORD_ERROR(-101101002, "密码输入有误！"),
 PARAMETER_MISSING(-101101003, "参数缺失！"),
 TOKEN_UNAUTHORIZED(-101101004, "token校验失败！"),
 TOKEN_EXPIRED(-101101005, "token过期！"),
 VERIFY_status_EXPIRED(-101101006, "验证码校验失败！"),
 SYSTEM_EXISTENT(-101101007, "系统名称已存在！"),
 FORBIDDEN_LOGIN(-101101008, "该账号被禁止登陆！"),
 OPERATION_FAIL(-101101009, "操作失败！"),
 DATA_NON_EXISTENT(-101101010, "数据不存在！"),
 FETCH_status_FAIL(-101101011, "获取验证码失败！");
 */

export default {
    SUCCESS: 0,
    DEFAULT_FAIL: -1024,
    USER_NON_EXISTENT: -10110100,
    PASSWORD_ERROR: -101101002,
    PARAMETER_MISSING: -101101003,
    TOKEN_UNAUTHORIZED: -101101004,
    TOKEN_EXPIRED: -101101005,
    VERIFY_status_EXPIRED: -101101006,
    SYSTEM_EXISTENT: -101101007,
    FORBIDDEN_LOGIN: -101101008,
    OPERATION_FAIL: -101101009,
    DATA_NON_EXISTENT: -101101010,
    FETCH_status_FAIL: -101101011,
    USER_NON_LOGIN: -101101013
};
