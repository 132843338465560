import HTTP from '@/utils/http'

export default class OrderModel extends HTTP {
  /**
   * 订单详情
   * @param {*} params
   */
  detail(params) {
    return this.get({
      url: '/ordercore/info/v1',
      params,
    })
  }

  /**
   * 预支付
   * @param {*} data
   */
  prepay(data) {
    return this.post({
      url: '/ordercore/pay/pre/v1',
      data,
    })
  }

  /**
   * 支付
   * @param {*} params
   */
  pay(params) {
    return this.get({
      url: '/ordercore/pay/v1',
      params,
    })
  }
}
